// extracted by mini-css-extract-plugin
export var buttonCreateContainer = "ProviderBrochureEditForm__buttonCreateContainer__aOxvn";
export var column = "ProviderBrochureEditForm__column__SC0GG";
export var documentRow = "ProviderBrochureEditForm__documentRow__UTeiN";
export var editor = "ProviderBrochureEditForm__editor__V79f7";
export var fileNameText = "ProviderBrochureEditForm__fileNameText__SBol3";
export var fileSizeText = "ProviderBrochureEditForm__fileSizeText__uQamb";
export var flexContainer = "ProviderBrochureEditForm__flexContainer__a5de7";
export var grid = "ProviderBrochureEditForm__grid__j_tvR";
export var iconDelete = "ProviderBrochureEditForm__iconDelete__DEz85";
export var iconView = "ProviderBrochureEditForm__iconView__QX65e";
export var labelContainer = "ProviderBrochureEditForm__labelContainer__x0qoD";
export var publishedAtPicker = "ProviderBrochureEditForm__publishedAtPicker__kFrir";
export var row = "ProviderBrochureEditForm__row__htyr7";
export var uploadDocumentButton = "ProviderBrochureEditForm__uploadDocumentButton__V4bL7";